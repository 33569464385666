import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../Layouts/Layout";
import { StyledBlogPostLink } from "../components/StyledLink";
import { BlogCard } from "../components/BlogCard";
import { ContentCard } from "../components/ContentCard";
import { BodyText, Heading } from "../components/Typography";

const BlogPage = ({
  data: {
    allMdx: { nodes },
  },
}) => {
  const posts = nodes.map((item, idx) => (
    <BlogCard key={idx}>
      <StyledBlogPostLink to={item.fields.slug}>
        {item.frontmatter.title}
      </StyledBlogPostLink>
    </BlogCard>
  ));
  return (
    <Layout>
      <ContentCard>
        <Heading>Writing</Heading>
        <BodyText>
          I like to write. I{"'"}m currently working on a larger project but
          here{"'"}s some stuff that doesn{"'"}t fit in with that.
        </BodyText>
      </ContentCard>
      <PostsGrid>{posts}</PostsGrid>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        frontmatter {
          title
          date
        }
        fields {
          slug
        }
      }
    }
  }
`;

const PostsGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  ${({
    theme: {
      breakpoints: { down, values },
    },
  }) => down(values.sm)} {
    grid-template-columns: 1fr;
  }
`;
