import React from "react";
import styled from "styled-components";

export const ContentCard = ({ children }) => {
  return <Card>{children}</Card>;
};

const Card = styled.div`
  background: ${({ theme }) => theme.palette.grey[700]};
  padding: 1rem;
  border-right: 2px solid ${({ theme }) => theme.palette.fluoro};
  border-left: 2px solid ${({ theme }) => theme.palette.fluoro};
  border-radius: 5px;
`;
